.demo-editor {
    height: 330px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    background-color: white;

}
@media only screen and (max-width: 650px) and (min-width: 200px) {
.demo-editor {
    height: 250px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    background-color: white;

}
}

.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 400px !important;

}

#outlined-select-course-helper-text {
    color: red;
}

@media print{@page {size: "tabloid"}}